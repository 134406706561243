// extracted by mini-css-extract-plugin
export var body_background = "#f8f9fa";
export var container_cookies = "cookies-module__container_cookies___eUeS5";
export var container_cookies__content = "cookies-module__container_cookies__content___2XP2S";
export var container_cookies__content_logo = "cookies-module__container_cookies__content_logo___0-d5A";
export var container_cookies__name = "cookies-module__container_cookies__name___UNaTJ";
export var container_cookies__subtitle = "cookies-module__container_cookies__subtitle___HDZT8";
export var container_cookies__text = "cookies-module__container_cookies__text___vqAyB";
export var container_cookies__title = "cookies-module__container_cookies__title___vo90D";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var lg = "1200px";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "#2ed3ae";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var white = "#fff";
export var xl = "1536px";
export var xxl = "2500px";