import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import * as styles from './cookies.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
const Cookies = ({ intl: { formatMessage } }) => {
  const routes = [
    'https://support.google.com/chrome/answer/95647?hl=es',
    'https://support.microsoft.com/es-es/products/windows?os=windows-10',
    'https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we',
    'https://support.apple.com/kb/ph5042?locale=es_ES',
  ];
  return (
    <main className={styles.container_cookies}>
      <title>Cookies</title>
      <article className={styles.container_cookies__content}>
        <div className={styles.container_cookies__content_logo}>
          <StaticImage src="../../images/icon.webp" alt="logo" placeholder="blurred"
            loading="lazy"
            layout="constrained"
            formats={["auto", "webp", "avif"]} />
        </div>
        <h1 className={styles.container_cookies__title}>
          {formatMessage({ id: 'titleCookies' })}
        </h1>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies1' })}
          <small className={styles.container_cookies__name}>
            {' '}
            Connecting Tech People S.L.{' '}
          </small>
          {formatMessage({ id: 'textCookies2' })}
        </p>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies3' })}
          <small className={styles.container_cookies__name}>
            {' '}
            Connecting Tech People S.L.{' '}
          </small>
          {formatMessage({ id: 'textCookies4' })}
          Pase de la Castellana, 91. 4to. Madrid, España,
          {formatMessage({ id: 'textCookies5' })}
        </p>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies6' })}
        </p>
        <h4 className={styles.container_cookies__subtitle}>
          {formatMessage({ id: 'subtitleCookies1' })}
        </h4>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies7' })}
        </p>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies8' })}
        </p>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies9' })}
        </p>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies10' })}
        </p>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies11' })}
        </p>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies12' })}
        </p>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies13' })}
        </p>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies14' })}
        </p>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies15' })}
        </p>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies16' })}
        </p>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies17' })}
        </p>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies18' })}
        </p>
        <ul>
          {routes.map((route) => (
            <li key={route}>
              <a href={route}>{route}</a>
            </li>
          ))}
        </ul>
        <p className={styles.container_cookies__text}>
          {formatMessage({ id: 'textCookies19' })}
        </p>
      </article>
    </main>
  );
};

export default injectIntl(Cookies);

Cookies.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};
